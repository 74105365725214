var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade",attrs:{"id":"course-modal","tabindex":"-1","role":"dialog"}},[_c('div',{staticClass:"modal-dialog modal-lg",attrs:{"role":"document"}},[(_vm.course)?_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",domProps:{"innerHTML":_vm._s(
                        _vm.highlightMatch(_vm.course.displayName, 'key', _vm.match[0]) + ' ' + _vm.course.title
                    )}}),_vm._m(0)]),_c('div',{staticClass:"modal-body"},[_c('h6',{domProps:{"innerHTML":_vm._s(_vm.highlightMatch(_vm.course.title, 'title', _vm.match[0]))}}),_c('div',{staticStyle:{"width":"100%","overflow-x":"auto"}},[_c('table',{staticClass:"m-color w-100",attrs:{"id":"sec-table"}},[_c('tbody',_vm._l((_vm.course.sections),function(section){return _c('tr',{key:section.section},[_c('td',[_vm._v(_vm._s(section.id))]),_c('td',[_vm._v(_vm._s(section.section))]),_c('td',{domProps:{"innerHTML":_vm._s(
                                        _vm.highlightMatch(
                                            section.topic,
                                            'topic',
                                            _vm.match[1].get(section.id)
                                        )
                                    )}}),_c('td',{domProps:{"innerHTML":_vm._s(
                                        _vm.highlightMatch(
                                            section.instructors,
                                            'instructors',
                                            _vm.match[1].get(section.id)
                                        )
                                    )}}),_c('td',[_vm._v(" "+_vm._s(section.dates)+" ")]),_c('td',_vm._l((section.meetings),function(meeting,idx){return _c('div',{key:idx},[_vm._v(" "+_vm._s(meeting.days)+" ")])}),0),_c('td',_vm._l((section.meetings),function(meeting,idx){return _c('div',{key:idx},[(_vm.formatLocationURL(meeting.room))?_c('a',{attrs:{"href":_vm.formatLocationURL(meeting.room),"target":"_blank"}},[_vm._v(_vm._s(meeting.room)+" ")]):[_vm._v(" "+_vm._s(meeting.room)+" ")]],2)}),0),_c('td',[_vm._v(_vm._s(section.status))]),_c('td',[_vm._v(" "+_vm._s(((section.enrollment) + "/" + (section.enrollment_limit) + "/" + (section.wait_list)))+" ")])])}),0)])]),_c('p',{staticClass:"mt-2",domProps:{"innerHTML":_vm._s(_vm.highlightMatch(_vm.course.description, 'description', _vm.match[0]))}}),_c('hr',{staticClass:"mb-2"}),_c('h6',[_vm._v("External Links:")]),(_vm.semester)?_c('div',_vm._l((_vm.links),function(item){return _c('button',{key:item.name,staticClass:"btn btn-outline-info mr-2 mb-2",on:{"click":function($event){return item.action(_vm.semester, _vm.course)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0):_vm._e()])]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])}]

export { render, staticRenderFns }